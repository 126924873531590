<template>
  <div>

    <el-tabs v-model="activeTabPaneName" class="user-tabs" @tab-click="handleTabClick">
      <el-tab-pane label="EVEMS-充電紀錄" name="evems-charging" style="padding: 0px 10px;">
        <el-row>
      <!--篩選區塊-->
      <!--<el-divider content-position="right"> 過濾條件 </el-divider>-->
        <div style="text-align: right;">
        <el-select v-model="listQuery.serviceId" size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length > 0">
          <el-option key="0" :label="$t('AllOperator')" value="0"></el-option>
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.stationId" size="small" :placeholder="$t('AllChargingStation')" class="filter-item-mid" >
          <el-option key="0" :label="$t('AllChargingStation')" value="0"></el-option>
          <el-option v-for="item in stationList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <el-select v-model="listQuery.type" size="small" :placeholder="$t('AllCategories')" class="filter-item-mid">
          <el-option v-for="item in typeOptions" :key="item.id" :label="item.label " :value="item.name" />
        </el-select>
        <!--
        <el-date-picker
            v-model="dateRange"
            size="small"
            class="filter-item"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="-"
            :start-placeholder="$t('Start')+$t('Date')"
            :end-placeholder="$t('End')+$t('Date')">
          </el-date-picker>
        -->
        <el-date-picker
            v-model="dateStart"
            size="small"
            class="filter-item"
            type="date"
            value-format="yyyy-MM-dd"
            >
            </el-date-picker>
        <el-date-picker
                v-model="dateEnd"
                size="small"
                class="filter-item"
                type="date"
                value-format="yyyy-MM-dd"
            >
        </el-date-picker>
        <el-input size="small" v-model="listQuery.searchTerm" placeholder="查詢名稱" class="filter-item" clearable style="width: 180px;"/>
      </div>
        </el-row>
        <el-divider content-position="right" >
          <span v-loading="statisticsLoading" style="font-weight: bold; font-size: 1.2em;">充電總度數: {{statisticsData.sumMeter == undefined? '':(statisticsData.sumMeter/1000)}} 度</span>
        </el-divider>
        <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; top: -10px; padding-left: 10px;" >
          <span >產製時間: {{ reportDate }} </span>
        </el-row>
        <el-table v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{ padding: '0', height: '40px' }" @sort-change="tableSortChange"><!-- @row-click="showDetail"-->
          <el-table-column align="center" type="index" label="#" >
            <template slot-scope="scope">
              <span>{{(listQuery.page) * listQuery.size + scope.$index + 1}}</span>
            </template>
          </el-table-column>
          <!--<el-table-column align="center" prop="id" :label="$t('ChargingSessionId')"  />-->
          <el-table-column align="center" prop="account_type" :label="$t('OperatorType')" sortable >
            <template slot-scope="scope">
              {{ formatAccountType(scope.row.accountType) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="account_value" label="名稱" sortable="custom" >
            <template slot-scope="scope" >
              <el-tooltip class="item" effect="dark" :content="scope.row.accountValue" placement="top-start" v-if="scope.row.accountType=='app'">
                <span>{{scope.row.accountValue.replace(scope.row.accountValue.substr(6,4),"****")}}</span>
              </el-tooltip>
              <span :title="scope.row.accountValue" v-else>{{ scope.row.accountType == "RFID"? scope.row.accountValueName:scope.row.accountValue }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="station_id" :label="$t('ChargingStation')" sortable="custom" >
            <template slot-scope="scope">
              <div v-if="scope.row.dataSource == 'EVEMS'">
                {{fetchStationName(scope.row.stationId)}}
              </div>
              <div v-else>
                {{ scope.row.stationName }}
              </div>
              <!--          {{ fetchStationName(scope.row.stationId) }}-->
            </template>
          </el-table-column>
          <el-table-column align="center" label="充電樁#槍">
            <template slot-scope="scope">
              {{ scope.row.chargePointName + "#" + scope.row.connectorId }}
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('ChargingTime')">
            <template slot-scope="scope">
              <div v-if="scope.row.dataSource == 'EVEMS'">
                {{ diffTime(scope.row.stopTime, scope.row.startTime) }}
              </div>
              <div v-else>
                {{ diffTimeSecond(scope.row.ocpiChargingTime) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('ChargingMeter')">
            <template slot-scope="scope">
              <div v-if="scope.row.dataSource == 'EVEMS'">
                {{ diffMetter(scope.row.meterStop, scope.row.meterStart) }}
              </div>
              <div v-else>
                {{scope.row.opciKwh}}
              </div>

            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" :label="$t('ChargingStatus')" >
            <template slot-scope="scope">
              {{ formatChargingStatus(scope.row.status) }}
            </template>
          </el-table-column>
<!--          <el-table-column align="center" prop="dataSource" :label="$t('DataSource')">-->
<!--            <template slot-scope="scope">-->
<!--              <el-tag v-if="scope.row.dataSource == 'EVEMS'" type="success">{{scope.row.dataSource}}</el-tag>-->
<!--              <el-tag v-else type="danger">{{scope.row.dataSource+"("+scope.row.serviceName+"#"+scope.row.serviceId+")"}}</el-tag>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column align="center" prop="billId" :label="$t('TradeId')" >
            <template slot-scope="scope">
              <el-link type="primary" @click.stop="showPayment(scope.row)">{{ scope.row.billId }}</el-link>
            </template>
          </el-table-column>
          <!--
          <el-table-column align="center" prop="billType" label="交易方式" />
          <el-table-column align="center" prop="billAmout" label="交易金額" />
          <el-table-column align="center" prop="billStatus" label="交易狀態" />-->
          <el-table-column align="center" prop="create_time" :label="$t('Create')+$t('Date')" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('Operate')" width="70px">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="showDetail(scope.row)" ><svg-icon iconName="view-details" /></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分頁組件-->
        <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
        <RecordDetail :dialogVisible="dialogDetailVisible" :activeTab="activeTab" :recordData="recordData" @close="dialogDetailOnClose()" />
<!--        <RecordDetailOcpi :dialogVisible="ocpiDialogDetailVisible" :activeTab="activeTab" :recordData="recordDataOcpi" @close="dialogDetailOnClose()"/>-->
      </el-tab-pane>
      <el-tab-pane v-if="ocpiServiceList.length > 0" label="OCPI-充電紀錄" name="ocpi-charging"  style="padding: 0px 10px;">
        <el-row>
          <!--篩選區塊-->
          <!--<el-divider content-position="right"> 過濾條件 </el-divider>-->
          <div style="text-align: right;">
            <el-select v-model="ocpiListQuery.serviceId" clearable size="small" :placeholder="checkRoleIfAdmin()" class="filter-item-mid" v-if="ocpiServiceList.length > 0">
              <el-option key="0" :label="checkRoleIfAdmin()" value="0"></el-option>
              <el-option v-for="item in ocpiServiceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
            </el-select>
            <el-select v-model="ocpiListQuery.stationId" clearable size="small" placeholder="全部充電站" class="filter-item-mid" >

              <el-option key="0" label="全部充電站" value="0"></el-option>
              <el-option v-for="item in ocpiStationList" :key="item.id" :label="item.name" :value="item.id" />

            </el-select>
            <el-select v-model="ocpiListQuery.type" clearable size="small" :placeholder="$t('AllCategories')" class="filter-item-mid">
              <el-option v-for="item in ocpiTypeOptions" :key="item.id" :label="item.label " :value="item.name" />
            </el-select>
            <el-date-picker
                v-model="dateStartOcpi"
                size="small"
                class="filter-item"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-date-picker
                v-model="dateEndOcpi"
                size="small"
                class="filter-item"
                type="date"
                value-format="yyyy-MM-dd"
            >
            </el-date-picker>
            <el-input size="small" v-model="ocpiListQuery.searchTerm" placeholder="查詢名稱" class="filter-item" clearable style="width: 180px;"/>
          </div>
        </el-row>
        <el-divider content-position="right" >

          <span v-loading="ocpiStatisticsLoading" style="font-weight: bold; font-size: 1.2em;">充電總度數: {{ocpiStatisticsData.sumOcpiKwh == undefined? '':(ocpiStatisticsData.sumOcpiKwh)}} 度</span>
          <span>{{$store.state.currentAccount.name.role}}</span>
        </el-divider>
        <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; top: -10px; padding-left: 10px;" >
          <span >產製時間: {{ reportDate }} </span>
        </el-row>
        <el-table v-loading="ocpiListLoading" :data="ocpiList" style="width: 100%;" :cell-style="{ padding: '0', height: '40px' }" @sort-change="tableSortChange"><!-- @row-click="showDetail"-->
          <el-table-column align="center" type="index" label="#" >
            <template slot-scope="scope">
              <span>{{(ocpiListQuery.page) * ocpiListQuery.size + scope.$index + 1}}</span>
            </template>
          </el-table-column>
          <!--<el-table-column align="center" prop="id" :label="$t('ChargingSessionId')"  />-->
          <el-table-column align="center" prop="account_type" :label="$t('OperatorType')" sortable >
            <template slot-scope="scope">
              {{ formatAccountType(scope.row.accountType) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="account_value" label="名稱" sortable="custom" >
            <template slot-scope="scope" >
              <el-tooltip class="item" effect="dark" :content="scope.row.accountValue" placement="top-start" v-if="scope.row.accountType=='app'">
                <span>{{scope.row.accountValue.replace(scope.row.accountValue.substr(6,4),"****")}}</span>
              </el-tooltip>
              <span :title="scope.row.accountValue" v-else>{{ scope.row.accountType == "RFID"? scope.row.accountValueName:scope.row.accountValue }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="dataSource" label="充電供應商">
            <template slot-scope="scope">
              <!--              <el-tag v-if="scope.row.dataSource == 'EVEMS'" type="success">{{scope.row.dataSource}}</el-tag>-->
              {{scope.row.serviceName+"#"+scope.row.serviceId}}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="station_id" :label="$t('ChargingStation')" sortable="custom" >
            <template slot-scope="scope">
              <div v-if="scope.row.dataSource == 'EVEMS'">
                {{fetchStationName(scope.row.stationId)}}
              </div>
              <div v-else>
                {{ scope.row.stationName }}
              </div>
              <!--          {{ fetchStationName(scope.row.stationId) }}-->
            </template>
          </el-table-column>
          <el-table-column align="center" label="充電樁#槍">
            <template slot-scope="scope">
              {{ scope.row.chargePointName + "#" + scope.row.connectorId }}
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('ChargingTime')">
            <template slot-scope="scope">
              <div v-if="scope.row.dataSource == 'EVEMS'">
                {{ diffTime(scope.row.stopTime, scope.row.startTime) }}
              </div>
              <div v-else>
                {{ diffTimeSecond(scope.row.ocpiChargingTime) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('ChargingMeter')">
            <template slot-scope="scope">
              <div v-if="scope.row.dataSource == 'EVEMS'">
                {{ diffMetter(scope.row.meterStop, scope.row.meterStart) }}
              </div>
              <div v-else>
                {{scope.row.opciKwh}}
              </div>

            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" :label="$t('ChargingStatus')" >
            <template slot-scope="scope">
              {{ formatChargingStatus(scope.row.status) }}
            </template>
          </el-table-column>

<!--          <el-table-column align="center" prop="billId" :label="$t('TradeId')" >-->
<!--            <template slot-scope="scope">-->
<!--              <el-link type="primary" @click.stop="showPayment(scope.row)">{{ scope.row.billId }}</el-link>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <!--
          <el-table-column align="center" prop="billType" label="交易方式" />
          <el-table-column align="center" prop="billAmout" label="交易金額" />
          <el-table-column align="center" prop="billStatus" label="交易狀態" />-->
          <el-table-column align="center" prop="create_time" :label="$t('Create')+$t('Date')" sortable="custom">
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('Operate')" width="70px">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="showDetail(scope.row)" ><svg-icon iconName="view-details" /></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--分頁組件-->
        <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
<!--        <RecordDetail :dialogVisible="dialogDetailVisible" :activeTab="activeTab" :recordData="recordData" @close="dialogDetailOnClose()" />-->
        <RecordDetailOcpi :dialogVisible="ocpiDialogDetailVisible" :activeTab="activeTab" :recordData="recordDataOcpi" @close="dialogDetailOnClose()"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import RecordDetail from './RecordDetail.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import { apiUrl } from "@/utils/main";
import RecordDetailOcpi from "@/views/trade/RecordDetailOcpi";
export default {
  name: 'Record',
  components: {
    RecordDetailOcpi,
    Pagination,
    RecordDetail
  },
  mixins: [mixins],
  data() {
    return {
      activeTabPaneName:"evems-charging",
      dialogWidth: 0,
      total: 0,
      ocpiTotal: 0,
      list: [],
      ocpiList: [],
      listLoading: false,
      ocpiListLoading: false,
      listQuery: {
        type: "",
        serviceId: "0",
        stationId: "0",
        startdate: undefined,
        enddate: undefined,
        searchTerm: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      listOcpiLoading: false,
      ocpiListQuery: {
        type: "",
        serviceId: "0",       //OCPI充電提供商(CPO)
        stationId: "0",
        startdate: undefined,
        enddate: undefined,
        searchTerm: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      serviceList: [],
      ocpiServiceList: [],
      stationList: [],
      ocpiStationList: [],
      typeOptions: [
        {
          "name": "",
          "label": this.$t('AllCategories')
        },
        { "name": "web",
          "label": "網站"
        },
        { "name": "app",
          "label": "APP"
        },
        { "name": "RFID",
          "label": "RFID"
        },
        { "name": "sys",
          "label": "系統內部測試"
        }
      ],
      ocpiTypeOptions: [
        {
          "name": "",
          "label": this.$t('AllCategories')
        },
        { "name": "app",
          "label": "APP"
        },
        { "name": "RFID",
          "label": "RFID"
        }
      ],
      dateRange: undefined,
      dateStart: undefined,
      dateEnd:   undefined,
      dateStartOcpi: undefined,
      dateEndOcpi:   undefined,
      recordData: undefined,
      recordDataOcpi: undefined,
      activeTab: "charging",
      //chargingData: undefined,
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      dialogDetailVisible: false,
      ocpiDialogDetailVisible: false, //ocpi
      groupData: undefined, // 群組資訊
      dialogPreviewVisible: false,
      previewGroupId: undefined, // 預覽群組ID
      profileGroupType: 'general',
      statisticsLoading: false,
      ocpiStatisticsLoading: false,
      statisticsData: {},
      ocpiStatisticsData: {},
      reportDate: this.formatCurrentDateTime()
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function (newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.enabled': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'listQuery.serviceId': function () {
      this.listQuery.page = 0
      this.stationList = []
      /* 因為route傳過來的serviceId等同讓listQuery.serviceId發生變化,為了避免listQuery.stationId在這被洗成"0" 因此加上判斷 */
      if(this.$route.params.stationId != undefined) {
        this.listQuery.stationId = Number(this.$route.params.stationId)
        this.$route.params.stationId = undefined
      } else
        this.listQuery.stationId = "0"
      /*--------------------------------------------------------------------------------------------------------------------*/
      this.getStationOptions()
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'ocpiListQuery.serviceId': function () {
      this.ocpiListQuery.page = 0
      this.ocpiStationList = []
      this.getOcpiStationOptions()
      this.resetOcpiPage(this.getOcpiList)
      this.getOcpiStatistics()
    },
    'listQuery.stationId': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'ocpiListQuery.stationId': function (){
      this.ocpiListQuery.page = 0
      this.resetOcpiPage(this.getOcpiList)
      this.getOcpiStatistics()

    },
    'listQuery.type': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },
    'ocpiListQuery.type': function () {
      this.ocpiListQuery.page = 0
      this.resetOcpiPage(this.getOcpiList)
      this.getOcpiStatistics()
    },
    'listQuery.searchTerm': function () {
      if(this.listQuery.searchTerm != undefined && this.listQuery.searchTerm.length > 0) {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
        this.dateStart = undefined
        this.dateEnd = undefined
      }
      this.listQuery.page = 0
      this.resetPage(this.getList)
      this.getStatistics()
    },

    'ocpiListQuery.searchTerm': function () {
      if(this.ocpiListQuery.searchTerm != undefined && this.ocpiListQuery.searchTerm.length > 0) {
        this.ocpiListQuery.startdate = undefined
        this.ocpiListQuery.enddate = undefined
        this.dateStartOcpi = undefined
        this.dateEndOcpi = undefined
      }
      this.ocpiListQuery.page = 0
      this.resetOcpiPage(this.getOcpiList)
      this.getOcpiStatistics()
    },
    /*
    dateRange: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateRange[0]
        this.listQuery.enddate = this.dateRange[1]
      } else {
        this.listQuery.startdate = undefined
        this.listQuery.enddate = undefined
      }
      this.resetPage(this.getList)
      this.getStatistics()
    }, */
    dateStart: function(val) {
      if (val) {
        this.listQuery.startdate = this.dateStart
      } else {
        this.listQuery.startdate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.getStatistics()
    },
    dateStartOcpi: function(val) {
      if (val) {
        this.ocpiListQuery.startdate = this.dateStartOcpi
      } else {
        this.ocpiListQuery.startdate = undefined
      }
      this.listAll= [];
      this.ocpiListQuery.page = 0
      this.resetOcpiPage(this.getOcpiList)
      this.getOcpiStatistics()
    },
    dateEnd: function(val) {
      if (val) {
        this.listQuery.enddate = this.dateEnd
      } else {
        this.listQuery.enddate = undefined
      }
        this.listAll= [];
        this.listQuery.page = 0
        this.resetPage(this.getList)
        this.getStatistics()
    },
    dateEndOcpi: function(val) {
      if (val) {
        this.ocpiListQuery.enddate = this.dateEndOcpi
      } else {
        this.ocpiListQuery.enddate = undefined
      }
      this.listAll= [];
      this.ocpiListQuery.page = 0
      this.resetOcpiPage(this.getOcpiList)
      this.getOcpiStatistics()
    },
  },
  created() {

    console.log(this.$route.params.start)
    if(this.$route.params.start != undefined) {
      this.listQuery.startdate = this.$route.params.start
      this.dateStart = this.$route.params.start
    }
    console.log(this.$route.params.start)
    if(this.$route.params.end != undefined) {
      this.listQuery.enddate = this.$route.params.end
      this.dateEnd = this.$route.params.end
    }
    //console.log(this.$route.query)
    if(this.$route.query.start != undefined) {
      //console.log(this.$route.query.start)
      this.listQuery.startdate = this.$route.query.start
      this.dateStart = this.$route.query.start
    }
    if(this.$route.query.end != undefined) {
      //console.log(this.$route.query.end)
      this.listQuery.enddate = this.$route.query.end
      this.dateEnd = this.$route.query.end
    }

    // 如果都沒有預設的內容，則顯示最近一周的資訊
    if(this.listQuery.startdate==undefined && this.listQuery.enddate==undefined) {
      let date = new Date()
      let daysAgo = new Date()
      daysAgo = daysAgo.setDate(daysAgo.getDate()-7)
      this.listQuery.startdate = this.parseDateTime(daysAgo)
      this.dateStart = this.parseDateTime(daysAgo)
      this.listQuery.enddate = this.parseDateTime(date)
      this.dateEnd = this.parseDateTime(date)
    }

    // for ocpi-充電紀錄 如果都沒有預設的內容，則顯示最近一周的資訊
    if(this.ocpiListQuery.startdate==undefined && this.ocpiListQuery.enddate==undefined) {
      let date = new Date()
      let daysAgo = new Date()
      daysAgo = daysAgo.setDate(daysAgo.getDate()-7)
      this.ocpiListQuery.startdate = this.parseDateTime(daysAgo)
      this.dateStartOcpi = this.parseDateTime(daysAgo)
      this.ocpiListQuery.enddate = this.parseDateTime(date)
      this.dateEndOcpi = this.parseDateTime(date)
    }

    //this.dateRange = [this.listQuery.startdate==undefined?"":this.listQuery.startdate, this.listQuery.enddate==undefined?"":this.listQuery.enddate]
    this.listQuery.startdate==undefined?"":this.listQuery.startdate
    this.listQuery.enddate==undefined?"":this.listQuery.enddate

    this.ocpiListQuery.startdate==undefined?"":this.ocpiListQuery.startdate
    this.ocpiListQuery.enddate==undefined?"":this.ocpiListQuery.enddate

    this.dialogWidth = this.setDialogWidth()
    // await this.$store.dispatch("getUnitTable")


    this.getServiceOptions();
    this.getStationOptions();

    this.getOcpiServiceOptions();
    this.getOcpiStationOptions();


    /* Dashboard.vue route過來的參數 by企分會議需求 */
    if(this.$route.params.serviceId != undefined) this.listQuery.serviceId = Number(this.$route.params.serviceId)
    if(this.$route.params.stationId != undefined) this.listQuery.stationId = Number(this.$route.params.stationId)
    /* Dashboard.vue route過來的參數 by企分會議需求 */

    this.getStatistics()
    this.getOcpiStatistics()

    //上面設定日期後觸發 getList 動作
    this.resetPage(this.getList);
    this.resetOcpiPage(this.getOcpiList);



    //refreshToken();
  },
  beforeDestroy(){
    console.log("call beforeDestroy");
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }

    console.log("store",this.$store.state.currentAccount);
  },
  methods: {
    checkRoleIfAdmin(){
      let _role = null;
      let _roleName = "全部充電供應商";
      for(let i =0 ;i<this.$store.state.currentAccount.roleInfo.roleData.length; i++)
      {
        _role = this.$store.state.currentAccount.roleInfo.roleData[i];
        if(_role['name'] == "SUPERADMIN"){

          console.log(_role['name']);
          _roleName = "全部應用服務商";
          break;

        }
      }

      return _roleName;
    },
    handleTabClick(tab, event){
        if(tab.name == "ocpi-charging"){
          // this.getOcpiServiceOptions();
          //this.resetPage(this.getOcpiList);
        }
    },
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/charging', { params: this.listQuery }).then(res => {
        this.list = res.data.content
        console.log(this.list)
        this.total = res.data.totalElements
        this.listLoading = false

        this.reportDate = this.formatCurrentDateTime()
      })
    },
    getOcpiList() {
      this.ocpiListLoading = true;
      this.axios.get('/api/v1/charging/ocpi', { params: this.ocpiListQuery }).then(res => {
        this.ocpiList = res.data.content
        console.log(this.ocpiList)
        this.ocpiTotal = res.data.totalElements
        this.ocpiListLoading = false

        this.reportDate = this.formatCurrentDateTime()
      })

    },
    getServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/service/enabled').then(res => {
        this.serviceList = res.data
      })
    },
    //系統管理員:回傳系統全部的應用服務商(eMSP); 非系統管理員:回傳其合作的全部充電供應商(CPO)
    getOcpiServiceOptions() {
      this.ocpiListLoading = true
      this.axios.get('/api/v1/common/ocpi/service/enabled/emsp').then(res => {
        this.ocpiServiceList = res.data
      })
    },
    getStationOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/station/enabled', { params: this.listQuery }).then(res => {
        this.stationList = res.data;
        console.log("stationList",this.stationList);
      })
    },
    getOcpiStationOptions() {
      this.ocpiListLoading = true
      this.axios.get('/api/v1/common/ocpi/station/enabled', { params: this.ocpiListQuery }).then(res => {
        this.ocpiStationList = res.data;
        console.log("ocpiStationList",this.ocpiStationList);
      })
    },
    async getStatistics() {
      this.statisticsLoading = true
      this.axios.get('/api/v1/charging/statistics', { params: this.listQuery }).then(res => {
        this.statisticsData = res.data
        this.statisticsLoading = false
      })
    },
    async getOcpiStatistics() {
      this.ocpiStatisticsLoading = true
      this.axios.get('/api/v1/charging/ocpi/statistics', { params: this.ocpiListQuery }).then(res => {
        this.ocpiStatisticsData = res.data
        console.log("ocpiStatisticsData",this.ocpiStatisticsData);
        this.ocpiStatisticsLoading = false
      })
    },
    showDetail(row) {
      console.log("showDetail")
      if(row.dataSource == "OCPI")
      {
        this.ocpiDialogDetailVisible = true;
        this.recordDataOcpi = row;
        this.recordData = undefined;
      }
      else{
        this.dialogDetailVisible = true;
        this.recordData = row;
        this.recordDataOcpi = undefined;
      }
      this.activeTab = "charging"

    },
    showPayment(row) {
      console.log("showPayment")
      this.dialogDetailVisible = true
      this.activeTab = "payment"
      this.recordData = row
    },
    dialogDetailOnClose() {
      this.dialogDetailVisible = false
      this.ocpiDialogDetailVisible = false
      this.recordData = undefined
      this.recordDataOcpi = undefined
      //this.resetPage(this.getList)
    },
    fetchStationName(stationId){
      let stationName = stationId;
      this.stationList.forEach(function(station){
        if(station.id == stationId){
          stationName = station.name;
          return;
        }
      });
      return stationName;
    },
    diffTime(stopTime, startTime) {
      let stop_date = new Date(stopTime);
      let start_date = new Date(startTime);
      if(stop_date < start_date) {
        return "ERR";
      }
      let secs = (stop_date - start_date) / 1000;
      let hr = Math.floor(secs / 3600);
      let min = Math.floor((secs - (hr * 3600)) / 60);
      let sec = parseInt(secs - (hr * 3600) - (min * 60));

      while (min.length < 2) { min = '0' + min; }
      while (sec.length < 2) { sec = '0' + sec; }
      if (hr) hr += '時';
      return hr + min + '分' + (sec > 0 ? sec + '秒' : '');
    },
    //add for OCPI-Session
    diffTimeSecond(secs) {
      let hr = Math.floor(secs / 3600);
      let min = Math.floor((secs - (hr * 3600)) / 60);
      let sec = parseInt(secs - (hr * 3600) - (min * 60));

      while (min.length < 2) { min = '0' + min; }
      while (sec.length < 2) { sec = '0' + sec; }
      if (hr) hr += '時';
      return hr + min + '分' + (sec > 0 ? sec + '秒' : '');
    },

    formatAccountType(type) {
      if(type == "sys") type = "系內部測試"
      else if(type == "app") type = "APP"
      else if(type == "web") type = "網站"
      return type
    },
    // 自定義表格Header,例如：給表頭加一個icon，滑鼠移入icon顯示隱碼規則提示文字
    renderAccountHeaderMethods(h, {column}) {
      return h(
          'div', {
            style: 'display:inline-flex;margin:auto;'
          },
          [
            h('span', column.label),
            h('promptMessages', {
              props: {
                messages: [
                  '隱碼規則：隱藏後4碼'
                ]
              }
            })
          ]
      )
    },

  }
}
</script>
<style lang="scss" scoped></style>
