<template>
  <el-dialog width="720px" title="OCPI充電紀錄" :visible="dialogVisible" @close="dialogDataOnClose()" style="padding-top: 10px;">
    <el-tabs v-model="activeName" class="record-tabs" @tab-click="handleClick">

      <el-tab-pane v-loading="chargingloading" label="充電紀錄" name="charging" style="padding: 0px 10px;">
        <!-- 充電紀錄 -->
        <el-row :gutter="10" style="font-size:1em; font-weight: bold;">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: left;">
            充電序號: {{ chargingData.id }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: right;">
            建立日期: {{ chargingData.createTime }}
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電站: </span>{{ chargingData.stationName }} ({{ chargingData.stationId }})
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電站地址: </span>{{ chargingData.stationAddress }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電樁: </span>{{ chargingData.chargePointName }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電槍編號: </span>#{{ chargingData.connectorId }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
<!--            <span class="data-label">總充電時間: </span>{{ diffTime(chargingData.stopTime, chargingData.startTime) }}-->
            <span class="data-label">總充電時間: </span>{{ diffTimeSecond(chargingData.ocpiChargingTime) }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
<!--            <span class="data-label">總充電度數: </span>{{ diffMetter(chargingData.meterStop, chargingData.meterStart) }}-->
            <span class="data-label">總充電度數: </span>{{ chargingData.opciKwh }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電狀態: </span>
            <el-tag type="success" size="small" effect="dark" v-if="chargingData.status == 'completed'">充電完成</el-tag>
            <el-tag type="danger" size="small" effect="dark" v-else-if="chargingData.status != undefined && chargingData.status.startsWith('fail')">{{ formatChargingStatus(chargingData.status) }}</el-tag>
            <el-tag type="info" size="small" effect="dark" v-else>{{ formatChargingStatus(chargingData.status) }}</el-tag>
          </el-col>
        </el-row>
        <el-divider content-position="left">充電明細</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電交易類型: </span>{{ formatAccountType(chargingData.accountType) }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">類型描述: </span>{{ chargingData.accountType=="RFID"?chargingData.accountValueName+"("+chargingData.accountValue+")":chargingData.accountValue }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">開始時間: </span>{{ chargingData.startTime }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">結束時間: </span>{{ chargingData.stopTime }}
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">
            <span class="data-label">充電歷程(Charging Periods): </span>
            <div class="block" style="text-align: left;margin-top: 10px;">
              <el-timeline>
                <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :icon="activity.icon"
                    :type="activity.type"
                    :color="activity.color"
                    :size="activity.size"
                    :timestamp="activity.timestamp">
                  {{activity.content}}
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-col>

<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label">電錶開始度數: </span>{{ chargingData.meterStart }}-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label">電錶結束度數: </span>{{ chargingData.meterStop }}-->
<!--          </el-col>-->
        </el-row>
        <el-row style="margin-top: 10px;">

        </el-row>

<!--        <el-divider content-position="left">OCPP 充電交易資訊</el-divider>-->
<!--        <el-row :gutter="20">-->
<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label" style="width: 140px;">交易ID: </span>{{ chargingData.ocppTransactionId }}-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label" style="width: 140px;">OCPP開始時間: </span>{{ chargingData.ocppStartTime }}-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label" style="width: 140px;">OCPP結束時間: </span>{{ chargingData.ocppStopTime }}-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label" style="width: 140px;">OCPP電錶開始度數: </span>{{ chargingData.ocppMeterStart }}-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label" style="width: 140px;">OCPP電錶結束度數: </span>{{ chargingData.ocppMeterStop }}-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label" style="width: 140px;">交易結束原因: </span>{{ chargingData.ocppTransactionStopReason }}-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--            <span class="data-label" style="width: 140px;">遠端交易ID標籤: </span>{{ chargingData.ocppRemoteStartTransactionIdTag }}-->
<!--          </el-col>-->
<!--        </el-row>-->
      </el-tab-pane>
      <!-- End 充電紀錄 -->
      <!-- 交易紀錄 -->
<!--      <el-tab-pane v-loading="paymentloading" label="交易紀錄" name="payment" style="padding: 0px 10px;">-->
<!--        <div v-if="nopayment">-->
<!--          <el-row :gutter="10" style="font-size:1em; font-weight: bold;">-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: center; padding-top: 30px;">-->
<!--              無交易紀錄-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--        </div>-->
<!--        <div v-else>-->
<!--          <el-row :gutter="10" style="font-size:1em; font-weight: bold;">-->
<!--            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: left;">-->
<!--              交易編號: {{ paymentData.id }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" style="text-align: right;">-->
<!--              建立日期: {{ paymentData.createTime }}-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--          <el-row :gutter="20">-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">金流平台: </span>{{ paymentData.paymentPlatform }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">金流平台交易ID: </span><el-link type="success" @click="showTradeDetail()"> {{ paymentData.paymentTradeId }} </el-link>&nbsp;-->
<!--              &lt;!&ndash;Modified by Lillian (20240603): payment_records之status:-->
<!--                  //OLD: -1=交易未完成, 0=交易(請款)成功, 1=待請款, 2=退款中, 3=退款完成, 4=退款失敗, 5=補繳中, 6=補繳完成, 7=補繳失敗, 8=交易失敗(系統錯誤).-->
<!--                  //NEW: -1=交易未完成, 0=交易(請款)完成, 1=請款中, 2=退款中, 3=退款完成, 4=退款失敗, 5=補繳中, 9=付款失敗 (Modified by Lillian_20240822)-->
<!--                  &ndash;&gt;-->
<!--              <el-tag type="success" size="small" effect="plain" v-if="paymentData.status == 0">交易成功</el-tag>-->
<!--              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.status == -1">交易未完成</el-tag>-->
<!--              <el-tag type="primary" size="small" effect="plain" v-else-if="paymentData.status == 1">請款中</el-tag>-->
<!--              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.status == 2">退款中</el-tag>-->
<!--              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.status == 3">退款完成</el-tag>-->
<!--              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.status == 4">退款失敗</el-tag>-->
<!--              <el-tag type="warning" size="small" effect="plain" v-else-if="paymentData.status == 5">補繳中</el-tag>-->
<!--              <el-tag type="danger" size="small" effect="plain" v-else>交易失敗({{ paymentData.msg }})</el-tag>-->
<!--              &lt;!&ndash;-->
<!--              以下是針對資料表為 payment_records, 且交易狀態為成功(status=0)之情況-->
<!--              -(業務單位權限), 發票狀態為未開立(invoice_status=-1), 則顯示 [執行退款].-->
<!--              -(業務單位權限), 當發票狀態為已開立(invoice_status=0)或開立中(invoice_status=1), 則顯示 [同意退款].-->
<!--              &ndash;&gt;-->
<!--              &lt;!&ndash;點選一次後就不再顯示按鈕: 因為payment_records之status會變成2(待退款)&ndash;&gt;-->
<!--              &lt;!&ndash;Note: User一刷卡後, 即為請款中(status=1), 直到每日18:00(Tappay)向銀行請款,且隔天確認請款成功後, 才會將status改為0.&ndash;&gt;-->
<!--              &nbsp;-->
<!--              <el-button size="small" type="danger" style="padding: 5px 15px;" v-if="paymentData.status==1 && paymentData.invoiceStatus==-1" @click="doRefund(paymentData.id)">執行退款</el-button>-->
<!--              <el-button size="small" type="danger" style="padding: 5px 15px;" v-if="paymentData.status==0 && (paymentData.invoiceStatus==0 || paymentData.invoiceStatus==1)" @click="doAgreeRefund(paymentData.id)">同意退款</el-button>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--          <el-divider content-position="left">交易內容</el-divider>-->
<!--          <el-row :gutter="20">-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">充電站: </span>{{ paymentData.stationName }} ({{ paymentData.stationAddress }})-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">開始充電時間: </span>{{ paymentData.chargingStartTime }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">結束充電時間: </span>{{ paymentData.chargingStopTime }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">總充電時間: </span>{{ formatSecond(paymentData.totalChargingTime) }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">總充電度數: </span>{{ paymentData.totalChargingMeter }}-->
<!--            </el-col>-->
<!--          </el-row>-->

<!--          <el-divider content-position="left">付款資訊</el-divider>-->
<!--          <el-row :gutter="20">-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">會員: </span>-->
<!--              <el-tooltip class="item" effect="dark" :content="paymentData.appAccountUser" placement="top-start" v-if="paymentData.appAccountUser != null" >-->
<!--                <span v-if="paymentData.appAccountUser != null" >{{paymentData.appAccountUser.replace(paymentData.appAccountUser.substr(6,4),"****")}}</span>-->
<!--              </el-tooltip>-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">會員姓名: </span>-->
<!--              <el-tooltip v-if="paymentData.appAccountName != null" class="item" effect="dark" :content="paymentData.appAccountName" placement="top-start">-->
<!--                <span v-if="paymentData.appAccountName != null">{{hideName(paymentData.appAccountName)}}</span>-->
<!--              </el-tooltip>-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">付款方式: </span>{{ paymentData.cardIssuer }} ****{{ paymentData.cardLastFour }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">資費: </span>{{ paymentData.rate }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">總費用: </span><span class="data-amount">{{ paymentData.amount }}</span>-->
<!--            </el-col>-->
<!--          </el-row>-->

<!--          <el-divider content-position="left">發票資訊</el-divider>-->
<!--          <el-row :gutter="20" v-if="paymentData.invoiceSetting == -1">-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;" >-->
<!--              <span >無發票資訊</span>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--          <el-row :gutter="20" v-else>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              &lt;!&ndash; Modified by Lillian (20240913)-->
<!--              <span class="data-label">發票設定: </span>{{ formatInvoiceSetting(paymentData.invoiceSetting) }}-->
<!--              &ndash;&gt;-->
<!--              <span class="data-label">發票設定: </span>{{ formatNewInvoiceSetting(paymentData.carrierType) }}{{ paymentData.carrierData }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">開立發票結果: </span>{{ paymentData.recInvoiceId }}-->
<!--              <el-tag type="success" size="small" effect="plain" v-if="paymentData.invoiceStatus == 0">成功</el-tag>-->
<!--              <el-tag type="warning" size="small" effect="plain" v-else-if="paymentData.invoiceStatus == -1">未開立</el-tag>-->
<!--              <el-tag type="success" size="small" effect="plain" v-else-if="paymentData.invoiceStatus == 1">開立中</el-tag>&lt;!&ndash;Added by Lillian (20240828)&ndash;&gt;-->
<!--              <el-tag type="danger" size="small" effect="plain" v-else-if="paymentData.invoiceStatus == 2">開立失敗</el-tag>&lt;!&ndash;Added by Lillian (20240914)&ndash;&gt;-->
<!--              <el-tag type="success" size="small" effect="plain" v-else>已作廢({{ paymentData.invoiceMsg }})</el-tag>-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;" >-->
<!--              <span class="data-label">發票號碼: </span> {{ paymentData.invoiceNumber }}-->
<!--            </el-col>-->
<!--            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 5px;">-->
<!--              <span class="data-label">開立時間: </span>{{ paymentData.invoiceDate }}-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--        </div>-->
<!--      </el-tab-pane>-->
      <!-- End 交易紀錄 -->

    </el-tabs>

    <TradeDetail :tradeDialogVisible="tradeDialogDetailVisible" :paymentRecordData="paymentRecordData" @close="tradeDialogOnClose()" append-to-body/>

    <template #footer>
      <div class="dialog-footer" style="text-align: center;">
        <el-button @click="dialogDataOnClose()">關閉</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import TradeDetail from "@/views/trade/TradeDetail";

export default {
  name: "RecordDetailOcpi",
  components: {
    TradeDetail
  },
  mixins: [mixins],
  props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
    recordData: {
      require: false,
      default: undefined,
      type: Object
    },
    activeTab: {
      require: false,
      default: "charging",
      type: String
    },
    dialogVisible: {
      require: true,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      dialogWidth: 0,
      activeName: undefined,
      paymentData: {},
      paymentRecordData: undefined,
      chargingData: {},
      record: {},
      chargingloading: false,
      paymentloading: false,
      nopayment: false,
      tradeDialogDetailVisible: false,
      activities: []
    };
  },
  watch: {
    recordData(val) {
      if (val) {
        console.log("recordData",val);
        this.record.id = val.id
        let chargingSessionId = undefined
        let paymentId = undefined
        console.log(val.chargingSessionRecordId)
        if (val.chargingSessionRecordId != undefined) {
          this.activeName = "payment"
          paymentId = val.id
          chargingSessionId = val.chargingSessionRecordId
        } else {
          this.activeName = "charging"
          chargingSessionId = val.id
          paymentId = val.billId
        }
        console.log("active:" + this.activeName)
        console.log("paymentId:" + paymentId)
        if(paymentId == "" || paymentId == null ) {
          this.nopayment = true
        } else {
          this.getPaymentRecord(paymentId)
        }
        this.getChargingSessionRecord(chargingSessionId)
      }
    },
    activeTab(val) {
      if(val) {
        this.activeName = val
      }
    }

  },

  async created() {
    this.dialogWidth = this.setDialogWidth()


  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getPaymentRecord(id) {
      this.paymentloading = true
      this.axios.get('/api/v1/payment/record/'+id).then(res => {
        this.paymentData = res.data
        this.paymentloading = false
      })
    },
    getChargingSessionRecord(id) {
      let vm = this;
      this.chargingloading = true
      this.axios.get('/api/v1/charging/ocpi/record/' + id).then(res => {
        this.chargingData = res.data
        console.log("chargingData",this.chargingData);
        vm.activities = [];
        if(this.chargingData.ocpiChargingPeriods != null){
          let timeline = JSON.parse(this.chargingData.ocpiChargingPeriods);
          console.log(timeline);
          let _item = {}
          let _dimension = "";

          timeline.forEach((item) => {
            console.log(item.dimensions);
            _item = {}
            _dimension = "";
            item.dimensions.forEach((dimension) => {
              _dimension = _dimension +" "+dimension.type+"("+dimension.volume+")"
            })
            _item.content = _dimension;
            _item.timestamp = "開始時間:"+vm.parseDateTimeToHHMMSS(item.start_date_time);
            this.activities.push(_item);
          });
        }
        this.chargingloading = false
      })
      console.log(this.activities);
    },
    dialogDataOnClose() {
      this.paymentData = {}
      this.chargingData = {}
      this.nopayment = false
      this.$emit('close') // 觸發父層 close
    },
    tradeDialogOnClose() {
      this.tradeDialogDetailVisible = false
      this.paymentRecordData = undefined
    },
    handleClick(tab, event) { // = (tab: TabsPaneContext, event: Event) => {
      console.log(tab, event)
    },
    showTradeDetail(){
      this.tradeDialogDetailVisible = true
      this.paymentRecordData = this.paymentData
    },
    formatInvoiceSetting(setting) {
      // 0_1SDGFJKL1  _分隔 ， 前面為類型，後面為值
      if(setting != undefined) {
        const invoices = setting.split(/_(.*)/s)
        if(invoices.length >= 1) {
          if(invoices[0] == 0) setting = "手機載具"
          else if(invoices[0] == 1) setting = "自然人憑證"
          else if(invoices[0] == 2) setting = "捐贈發票"
          else if(invoices[0] == 3) setting = "雲端會員"
          else if(invoices[0] == 4) setting = "公司名稱/統編"
          else if(invoices[0] == 5) setting = "中華電信會員載具" //Added by Lillian (20240830)-身分證號碼
          else if(invoices[0] == -1) setting = "無"

          if(invoices.length > 1) {
            setting = setting + ": " + invoices[1]
          }
        }
      }
      return setting;
    },
    formatNewInvoiceSetting(carrierType) {
      if(carrierType != undefined) {
        if(carrierType == 0) carrierType = "手機載具: "
        else if(carrierType == 1) carrierType = "自然人憑證: "
        else if(carrierType == 2) carrierType = "捐贈發票: "
        else if(carrierType == 3) carrierType = "雲端會員: "
        else if(carrierType == 4) carrierType = "公司統編: "
        else if(carrierType == 5) carrierType = "中華電信會員載具: " //Added by Lillian (20240830)-身分證號碼
        else if(carrierType == -1) carrierType = "無資料"
      }
      return carrierType;
    },
    formatAccountType(type) {
      if(type == "sys") type = "系內部測試"
      else if(type == "app") type = "APP"
      else if(type == "web") type = "網站"
      return type
    },
    doAgreeRefund(paymentId) { // [同意退款]
      const refundModel = {
        paymentId: paymentId
      };
      this.axios.put('/api/v1/payment/agreeRefund', refundModel).then(()=> {
        this.$message({
          showClose: true,
          message: "OK",
          type: 'success'
        })
        this.dialogDataOnClose() //close the window
        window.location.reload() //refresh the result
      }).catch(err => {
        console.log('err:' + err)
        if(err.data.includes('NotAdmin')) {
          this.$message({
            message: this.$t('NotAdmin'),
            type: 'error'
          })
        } else if(err.data.includes('OldRecord')) {
          this.$message({
            message: this.$t('OldRecord'),
            type: 'error'
          })
        } else{
          this.$message({
            message: "Fail",
            type: 'error'
          })
        }
      })
      //End--Added by Lillian (20240828)
    },
    doRefund(paymentId){ // [執行退款] Added by Lillian (20240903)
      const refundModel = {
        paymentId: paymentId
      };
      this.axios.put('/api/v1/payment/refund', refundModel).then(()=> {
        this.$message({
          showClose: true,
          message: "OK",
          type: 'success'
        })
        this.dialogDataOnClose() //close the window
        window.location.reload() //refresh the result
      }).catch(err => {
        console.log('err:' + err)
        if(err.data.includes('NotAdmin')) {
          this.$message({
            message: this.$t('NotAdmin'),
            type: 'error'
          })
        } else if(err.data.includes('RefundFail')) {
          this.$message({
            message: this.$t('RefundFail'),
            type: 'error'
          })
        } else if(err.data.includes('OldRecord')) {
          this.$message({
            message: this.$t('OldRecord'),
            type: 'error'
          })
        } else {
          this.$message({
            message: "Fail",
            type: 'error'
          })
        }
      })
    },
    //add for OCPI-Session
    diffTimeSecond(secs) {
      let hr = Math.floor(secs / 3600);
      let min = Math.floor((secs - (hr * 3600)) / 60);
      let sec = parseInt(secs - (hr * 3600) - (min * 60));

      while (min.length < 2) { min = '0' + min; }
      while (sec.length < 2) { sec = '0' + sec; }
      if (hr) hr += '時';
      return hr + min + '分' + (sec > 0 ? sec + '秒' : '');
    },
  }
}
</script>

<style>
 .el-timeline-item__content {
  color: gray;
}
 .el-timeline-item__timestamp {
   color: gray;
   line-height: 1;
   font-size: 13px;
 }
/*.el-timeline item__wrapper {*/
/*  position: absolute;*/
/*  padding-left: 10px;*/
/*  top: -3px;*/
/*}*/
</style>
